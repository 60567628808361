<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 class="container-dash">
        <div class="container-cabecera"></div>
        <h2 class="pl-1 text-medium font-size-2 text-primary-dark">Bienvenido, estos son los módulos disponibles</h2>
      </v-flex>
      <v-flex xs12 class="under-sec">
        <ItemDash :dataitem="items"></ItemDash>
      </v-flex>
    </v-layout>
  </div>

</template>
<script>
import axiosServices from '@/services/axiosServices.js';
import { mapGetters } from 'vuex';
import ItemDash from '@/components/Dash/ItemDash.vue';
export default {
  name:"DashBoard",
  components:{
    ItemDash 
  },
  data() {
    return {
       items: [],
    }
  },
  mounted() {
    if (this.getLogin == true) {
      this.getModules();
    }
  },
  methods: {
    getModules() {
      axiosServices.getConfiguration("/modules", true, `nIdPerfil=${this.getIdPerfil}`)
      .then(aGetModules => {
        this.items = aGetModules.aData;
      })
      //console.log(this.items)
    },
  },
   computed: {
    ...mapGetters(['getLogin', 'getIdUser', 'getIdDeparment', 'getIdPerfil'])
  },
  watch: {
    getLogin(bNewValue) {
      if (bNewValue == true) {
        this.getImgUser();
        this.getModules();
      }
    }
  }
}
</script>
<style lang="scss">
.container-dash{
  text-align: center;
  h2{
    padding: 5px;
    line-height: 1.2em;
  }
}
.container-cabecera{
  height: 200px;
  background-image: url('../assets/img/city-tech.svg');
  background-position: 50%;
  background-size: contain;
  @media screen and(max-width: 600px) {
    height: 150px;
  }
}


</style>